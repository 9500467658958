import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps, useLocation } from '@reach/router';
import styled from '@emotion/styled';

import Lost from './lost';

import { postLogs } from '../../apis/public/logs';
import SEO from '../seo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: clamp(20em, 40vh, 60em);
  max-width: max-content;
`;

export default function NotFound(_: RouteComponentProps) {
  const location = useLocation();
  useQuery(postLogs.QUERY_KEY, () => postLogs({ level: 'WARN', message: '404 encountered', location }), {
    onSuccess: () => {
      console.info('404 location has been logged');
    },
    onError: error => {
      console.error('Error logging 404 location', { error, location });
    },
  });

  return (
    <Container>
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>The page you are looking for doesn&apos;t exist</p>
      <Lost />
    </Container>
  );
}
