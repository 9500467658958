import React from 'react';

import NotFound from '../components/common/not-found';
import PublicLayout from '../layout/public-layout';

export default function NotFoundPage() {
  return (
    <PublicLayout>
      <NotFound />
    </PublicLayout>
  );
}
